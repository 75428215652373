<template>
  <main class="MemberManagement">
    <div class="EnterpriseMemberManagement" v-if="isShow">
      <div style="text-align: left;"><Head title="企业员工"></Head></div>
      <div class="el">
        <div class="el-item">
          <div class="btn-wrap">
            <el-button
              type="primary"
              size="small"
              @click="addCertificate"
              v-if="isShow2"
              >添加员工</el-button
            >
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column type="index" width="50" label="序号" />
            <el-table-column prop="name" label="员工姓名"> </el-table-column>
            <el-table-column prop="phone" label="手机号码"> </el-table-column>
            <el-table-column prop="idCard" label="身份证号"> </el-table-column>
            <el-table-column prop="email" label="邮箱"> </el-table-column>
<!--            <el-table-column prop="createAt" label="创建时间">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="modifiedAt" label="修改时间">-->
<!--            </el-table-column>-->
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="button-container">
                  <div class="btn-small">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="handleEdit(scope.row)"
                    >编辑</el-button>
                  </div>
                  <div class="btn-small">
                    <el-button
                        v-if="!scope.row.hasActive"
                        type="danger"
                        size="mini"
                        @click="handleDelete(scope.row.phone)"
                    >删除</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <Pagination
              :total="search.total"
              :page.sync="search.page"
              :limit.sync="search.size"
              @pagination="getData"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="AddMember" v-if="isShow3">
      <div style="text-align: left;"><Head title="添加成员"></Head></div>
      <el-form
        ref="addMemberForm"
        :model="addMemberForm"
        :rules="addMemberRules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="name" label="姓名：">
          <el-input
            v-model="addMemberForm.name"
            clearable
            type="text"
            placeholder="请输入员工姓名"
            tabindex="1"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号码：" >
          <el-input
            v-model="addMemberForm.phone"
            clearable
            type="text"
            placeholder="请输入手机号码"
            tabindex="2"
          ></el-input>
        </el-form-item>
        <el-form-item prop="idCard" label="身份证号：" >
          <el-input
            v-model="addMemberForm.idCard"
            clearable
            type="text"
            placeholder="请输入身份证号"
            tabindex="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input
            v-model="addMemberForm.email"
            clearable
            type="text"
            placeholder="请输入邮箱地址"
            tabindex="4"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="handleAddMember">提交</el-button>
      </el-form>
    </div>
    <el-dialog title="修改学员" :visible.sync="dialogVisible">
      <el-form
        ref="editMemberForm"
        :model="editMemberForm"
        :rules="editMemberRules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="name" label="姓名：">
          <el-input
            v-model="editMemberForm.name"
            clearable
            type="text"
            placeholder="请输入员工姓名"
            tabindex="1"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="editMemberForm.hasActive" prop="phone" label="手机号码：" >
          <el-input
            v-model="editMemberForm.phone"
            disabled
            clearable
            type="text"
            placeholder="请输入手机号码"
            tabindex="2"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="!editMemberForm.hasActive" prop="phone" label="手机号码：" >
          <el-input
            v-model="editMemberForm.phone"
            clearable
            type="text"
            placeholder="请输入手机号码"
            tabindex="2"
          ></el-input>
        </el-form-item>
        <el-form-item prop="idCard" label="身份证号：" >
          <el-input
            v-model="editMemberForm.idCard"
            clearable
            type="text"
            placeholder="请输入身份证号"
            tabindex="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input
            v-model="editMemberForm.email"
            clearable
            type="text"
            placeholder="请输入邮箱地址"
            tabindex="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetEdit">取 消</el-button>
        <el-button type="primary" @click="handleEditMember">确 定</el-button>
      </span>
    </el-dialog>
  </main>
</template>

<script>
import { getEnterpriseStaff, postEnterpriseStaff,enterpriseStaffDelete,editStaff } from '@/api/entCenter'
import Pagination from '@/components/Pagination'
import { isId15, isId18 } from '@/utils/validate'
import Head from './Head'
export default {
  name: 'MemberManagement',
  components: {
    Pagination,
    Head
  },
  data() {
    var rulesPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空!'))
      }else{
        callback()
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号不正确!'))
      }else{
        callback()
      }
    }
    var rulesIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证号码不能为空!'))
      } else {
        if (isId15(value) || isId18(value)) {
          return callback(new Error('请输入15位或18位身份证号码!'))
        }else{
          callback()
        }
      }
    }
    return {
      isShow: true,
      isShow2: true,
      isShow3: false,
      editMemberForm: {
        // name: '',
        // phone: '',
        // idCard: '',
        // email: ''
      },
      addMemberForm: {
        // name: '',
        // phone: '',
        // idCard: '',
        // email: ''
      },
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      dialogVisible: false,
      editMemberRules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: rulesPhone, trigger: 'blur' }],
        idCard: [{ required: true, validator: rulesIdCard, trigger: 'blur' }]
      },
      addMemberRules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: rulesPhone, trigger: 'blur' }],
        idCard: [{ required: true, validator: rulesIdCard, trigger: 'blur' }]
      },
      tableData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取列表
    getData() {
      getEnterpriseStaff().then((res) => {
        if (res.data.code === 0) {
          console.log(res)
          this.tableData = res.data.data.rows
          this.search.total = res.data.data.total
        }
      })
    },
    handleAddMember() {
      console.log(this.addMemberForm);
      this.$refs.addMemberForm.validate((valid) => {
        if (valid) {
          console.log('pass...')
          postEnterpriseStaff(this.addMemberForm).then((res) => {
            if(res.data.code === 0) {
              this.$message({
                type: 'success',
                message: `新增${res.data.message}`,
                onClose:()=>{
                  this.addMemberForm = {} // 重置数据
                  this.isShow = true
                  this.isShow2 = true
                  this.isShow3 = false
                  this.getData()
                }
              })
            }else{
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
            
          /* this.$router.push({
            path: 'MemberManagement'
          }) */
        }
      })
    },
    /* resetForm(formName) {
      this.$refs[formName].resetFields()
    }, */
    addCertificate() {
      this.isShow = false
      this.isShow2 = false
      this.isShow3 = true
    },
    handleEdit(row) {
      this.dialogVisible = true
      this.editMemberForm = row
    },
    // 删除
    handleDelete(phone) {
      //是否确认删除
      this.$confirm('是否确认删除该成员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        enterpriseStaffDelete({ids: [phone]}).then((res) => {
          if(res.data.code === 0) {
            this.$message({
              type: 'success',
              message: `删除${res.data.message}`,
              onClose:()=>{
                this.getData()
              }
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleEditMember() {
      this.$refs.editMemberForm.validate((valid) => {
        if (valid) {
          console.log('pass...')
          editStaff(this.editMemberForm).then((res) => {
            if(res.data.code === 0) {
              this.$message({
                type: 'success',
                message: `修改${res.data.message}`,
                onClose:()=>{
                  this.getData()
                }
              })
            }else{
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
          this.dialogVisible = false
        }
      })
    },
    resetEdit() {
      this.$refs.editMemberForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
.MemberManagement {
  em {
    display: flex;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .el {
    margin-top: 20px;
    .el-item {
      margin-top: 20px;
      .btn-wrap {
        margin: 10px 0;
        text-align: right;
      }
    }
  }
  .el-input {
    width: 50%;
    float: left;
  }
  .el-form-item {
    margin-left: 175px;
  }
  .pagination {
    margin-top: 30px;
  }
}

.button-container {
  display: flex;
  align-items: center;
}

.btn-small {
  margin-right: 8px;
}
</style>